import { createRouter,createWebHistory,createRouterMatcher} from 'vue-router';

const routesRoot = [
    {
        path: "/",
        name: "Login",
        component: () => import('@/views/login')
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home')
    },
    {
        path: "/:catchAll(.*)",     // 不识别的path自动匹配404
        redirect: '404'             
        // 注意这个404重定向，vue3不支持直接使用“*”匹配所有路由了，要使用:catchAll(.*)
    }
]

// 创建一个路由器
let handRouters = createRouter({
    history: createWebHistory(),
    routes: routesRoot,    // `routes: routes` 的缩写
})

// 将路由器对外暴露
export default handRouters;
