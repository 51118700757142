import { createApp } from 'vue'
import App from './App.vue'
import router from './framework/router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import SvgIcon from '../src/components/svgicon'
import '../src/assets/image/icons/index'

import axios from 'axios'



const application = createApp(App)

// application.provide('$axios',axios)
application.config.devtools = true
application.use(router)
application.use(ElementPlus)
application.component("svg-icon",SvgIcon)
application.mount('#app')

// 设置基础访问路径
axios.defaults.baseURL= process.env.VUE_APP_BASE_API
// 挂载全局对象
application.config.globalProperties.$axios = axios
